export function useLeftFormMenu() {
    const nuxtApp = useNuxtApp()
    const { data: sessionData, signOut } = useAuth()
    const hideMenu = ref(false)
    // if (useAuth().data.value?.accessToken )
    // cl('useLeftFormMenu', `${useAuth().data.value?.accessToken ? 'in' : 'not'}`)
    const initialValue = useAuth().data.value?.accessToken
        ? useCookie("fo-left-form-menu", {
            maxAge: 60 * 60 * 24 * 31,
            default: () => true,
        }) : true;

    // const leftFormMenu = computed(() => {
    //     return hideMenu.value
    //     // if (hideMenu.value) return false
    //     // return initialValue
    // })

    const leftFormMenu = computed(() => hideMenu.value)
    return { leftFormMenu, hideMenu }
}